import { REPO_MEMBERS_QUERY } from 'Commons/graphql';
import { ExecutionResult, DocumentNode } from 'graphql';
import { PageInfo, RepoMemberType } from 'types';

type RepoMembersQueryResult = {
  repoMembers: {
    edges: Array<{
      node: RepoMemberType;
    }>;
    pageInfo: PageInfo;
  };
};

type RepoMembersFetchMoreResult = {
  fetchMoreResult: RepoMembersQueryResult;
};

type FetchMoreMembersArgs = {
  query: DocumentNode;
  variables: {
    repoId?: string;
    membersEndCursor: string;
    cardId?: string;
  };
  context: {
    core: true;
  };
  updateQuery: (
    previousResult: RepoMembersQueryResult,
    fetchMoreResult: RepoMembersFetchMoreResult
  ) => RepoMembersQueryResult;
};

type FetchMoreMembersFunc = ({
  query,
  variables,
  context,
  updateQuery,
}: FetchMoreMembersArgs) => Promise<ExecutionResult>;

type LoadMoreMembersArgObj = {
  cardId?: string;
  repoId?: string;
};

export const updateRepoMembersQuery = (
  previousResult: RepoMembersQueryResult,
  { fetchMoreResult }: RepoMembersFetchMoreResult
): RepoMembersQueryResult => {
  const previousMembers = previousResult.repoMembers.edges;
  const {
    repoMembers: { edges: newMembers, pageInfo },
  } = fetchMoreResult;

  return {
    ...previousResult,
    repoMembers: {
      ...previousResult.repoMembers,
      edges: [...previousMembers, ...newMembers],
      pageInfo,
    },
  };
};

export const loadMoreMembers = (
  fetchMore: FetchMoreMembersFunc,
  { cardId, repoId }: LoadMoreMembersArgObj,
  membersEndCursor: string
): Promise<ExecutionResult> =>
  fetchMore({
    query: REPO_MEMBERS_QUERY,
    variables: { cardId, repoId, membersEndCursor },
    context: { core: true },
    updateQuery: updateRepoMembersQuery,
  });
