import {
  IntrospectionFragmentMatcher,
  apolloLogger,
  InMemoryCache,
  withClientState,
  ApolloLink,
  ApolloClient,
} from 'utils/apolloAbstraction/apolloAbstraction';

import apiLinks from './apiLinks';
import { isDevelopment } from './environment';
import introspectionQueryResultData from './fragmentTypes.json';
import { defaults, initialData, resolvers } from './store';

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
});

const useApolloLogger =
  process.env?.REACT_APP_APOLLO_LOGGER && isDevelopment() ? [apolloLogger] : [];

const getDataIdFromObject = ({ __typename: typename, id }) => {
  if (typename != null && id != null) {
    return `${typename}:${id}`;
  }
};

export const cache = new InMemoryCache({
  dataIdFromObject: getDataIdFromObject,
  fragmentMatcher,
});

cache.writeData(initialData);

const stateLink = withClientState({ resolvers, cache, defaults });

const link = ApolloLink.from([stateLink, ...useApolloLogger, apiLinks]);

export const client = new ApolloClient({
  cache,
  link,
});

window.__PIPEFY_APOLLO_CLIENT__ = client;
