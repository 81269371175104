import { Organization, User } from '@pipefyteam/types.base-types';

import { ResourcePipeWithRole } from 'AdminPortal/types/Pipe';
import { Pageable } from 'Commons/types';
import gql from 'graphql-tag';

export type PipesByUserVariables = {
  organizationId: Organization['id'];
  userUuid: User['uuid'];
  search?: string;
  first?: number;
  after?: string;
};

export type PipesByUser = {
  pipes: Pageable<ResourcePipeWithRole>;
};

export type PipesByUserResult = {
  organization: {
    id: Organization['id'];
    members: PipesByUser[];
  };
};

export const PIPES_BY_USER = gql`
  query pipesByUser(
    $organizationId: ID!
    $userUuid: ID!
    $first: Int
    $after: String
    $search: String
  ) {
    organization(id: $organizationId) {
      id
      members(uuid: $userUuid) {
        pipes(searchTerm: $search, after: $after, first: $first) {
          edges {
            node {
              availableRoles
              roleName
              pipe {
                color
                icon
                name
                uuid
                id
                canBeManaged
              }
            }
          }
          pageInfo {
            hasNextPage
            endCursor
            startCursor
          }
        }
      }
    }
  }
`;
