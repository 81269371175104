import { useMemo, useEffect } from 'react';

import { RepoMember } from '@pipefyteam/types.base-types';
import { t } from '@pipefyteam/utils.i18n';
import { handleErrorMessages } from '@pipefyteam/utils.noty';

import { REPO_MEMBERS_QUERY } from 'Commons/graphql';
import { loadMoreMembers } from 'Commons/helpers/RepoMembersHelpers';
import { useQuery } from 'utils/apolloAbstraction/apolloAbstraction';

type Props = {
  cardId?: string;
  repoId?: string;
  skip?: boolean;
};

export type UseRepoMembersResult = {
  loading?: boolean;
  repoMembers?: Array<RepoMember>;
};

export const adaptRepoMembers = (
  repoMember: Array<{ node: RepoMember }>
): Array<RepoMember> => {
  const result = repoMember
    .map(({ node }) => node)
    .sort((memberA, memberB) => memberA.name.localeCompare(memberB.name));
  return result.reduce<Array<RepoMember>>(
    (accumulator, currentValue, currentIndex) => {
      if (currentIndex > 0 && currentValue.id === result[currentIndex - 1].id) {
        return accumulator;
      }
      return [...accumulator, currentValue];
    },
    []
  );
};

const useRepoMembers = ({
  cardId,
  repoId,
  skip = false,
}: Props): UseRepoMembersResult => {
  const { data, loading, error, fetchMore } = useQuery(REPO_MEMBERS_QUERY, {
    context: { core: true },
    variables: {
      cardId,
      repoId,
    },
    skip,
  });

  const hasNextPage = data?.repoMembers?.pageInfo?.hasNextPage;
  const repoMembers = useMemo(
    () => adaptRepoMembers(data?.repoMembers?.edges || []),
    [data]
  );

  useEffect(() => {
    const pageInfo = data?.repoMembers?.pageInfo;

    if (pageInfo?.hasNextPage && !error && !loading) {
      const { endCursor: membersEndCursor } = pageInfo;
      loadMoreMembers(fetchMore, { cardId, repoId }, membersEndCursor);
    }
  }, [cardId, data, fetchMore, loading, error, repoId]);

  if (error) {
    handleErrorMessages({ message: t('errors.500') });
    return {
      loading: false,
      repoMembers: [],
    };
  }

  return {
    loading: loading || hasNextPage,
    repoMembers,
  };
};

export default useRepoMembers;
