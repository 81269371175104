import { PipefyAnalytics } from '@pipefyteam/analytics';

import { PipeUIAnalyticsType } from './types';

const identify: PipeUIAnalyticsType['identify'] = (
  properties: Record<string, unknown> = {}
) => {
  PipefyAnalytics.identify({
    properties: properties,
    userId: properties.userUuid,
  });
};

const initialize: PipeUIAnalyticsType['initialize'] = (): void => {
  PipefyAnalytics.init({
    projectSource: 'pipeui',
    url: `${process.env.REACT_APP_PIPEFY_URL}/analytics/track_event`,
  });
};

const track: PipeUIAnalyticsType['track'] = (params) => {
  PipefyAnalytics.track({ properties: params, type: params.type });
};

const trackWithMixpanel: PipeUIAnalyticsType['trackWithMixpanel'] = ({
  type,
  ...params
}) => {
  PipefyAnalytics.track({ properties: params, type });
};

export const PipeUIAnalytics: PipeUIAnalyticsType = {
  identify,
  initialize,
  track,
  trackWithMixpanel,
};

export default PipeUIAnalytics;
