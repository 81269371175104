import { useCallback, useEffect } from 'react';

import { parseSignUpData } from '@pipefyteam/utils.helpers';

import { useMe } from 'Commons/hooks';
import { UseMeResult } from 'Commons/hooks/useMe';
import { PipeUIAnalytics } from 'utils/PipeUIAnalytics';

import {
  PUBLIC_FORM_ROUTE_SUFFIX,
  PUBLIC_PHASE_FORM_ROUTE_SUFFIX,
  PUBLIC_PHASE_REDIRECT_ROUTE_SUFFIX,
} from './constants';
import { isRecentSignup } from './helpers/isRecentSignup';

let isAnalyticsInitialized = false;
let isIdentifyCalled = false;

export const setAnalyticsInitialized = (value = false): void => {
  isAnalyticsInitialized = value;
};

export const setIdentifyCalled = (value = false): void => {
  isIdentifyCalled = value;
};

export const InitializeAnalytics = (): null => {
  const isPublicPage =
    window.location.pathname.startsWith(PUBLIC_FORM_ROUTE_SUFFIX) ||
    window.location.pathname.startsWith(PUBLIC_PHASE_FORM_ROUTE_SUFFIX) ||
    window.location.pathname.startsWith(PUBLIC_PHASE_REDIRECT_ROUTE_SUFFIX);

  const { me: user, loading } = useMe({}, isPublicPage);

  const identify = useCallback((user: UseMeResult['me']) => {
    if (user) {
      const {
        uuid,
        locale,
        email,
        created_at,
        signupData,
        currentOrganizationRole,
      } = user;

      const isUserARecentSignup = isRecentSignup(created_at);

      const {
        signup_data: { Experience: experience, area },
      } = parseSignUpData(signupData);

      PipeUIAnalytics.identify({
        userUuid: uuid,
        Language: locale,
        email,
        area,
        company_size: experience?.['Company Size'],
        use_case: experience?.['Use Case'],
        user_department: experience?.['Department'],
        recentSignup: isUserARecentSignup,
        role: {
          organization: currentOrganizationRole,
        },
      });
    } else {
      PipeUIAnalytics.identify({});
    }
    setIdentifyCalled(true);
  }, []);

  const init = useCallback(() => {
    PipeUIAnalytics.initialize();

    setAnalyticsInitialized(true);
  }, []);

  useEffect(() => {
    if (!isAnalyticsInitialized) init();
  }, [init]);

  useEffect(() => {
    if (!loading && !isIdentifyCalled) identify(user);
  }, [loading, user, identify]);

  return null;
};
