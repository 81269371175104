import { useState, useCallback, useEffect, useMemo } from 'react';

import { executeAfterTimeout } from '@pipefyteam/utils.helpers';
import { t } from '@pipefyteam/utils.i18n';
import { handleErrorMessages } from '@pipefyteam/utils.noty';

import { DocumentNode } from 'graphql';
import get from 'lodash/get';
import { Context, PageInfo, UpdateQuery } from 'types';
import {
  ApolloError,
  ApolloQueryResult,
  NetworkStatus,
  QueryHookOptions,
  QueryResult,
  useQuery,
} from 'utils/apolloAbstraction/apolloAbstraction';

type PaginationVariables<Variables> =
  | Variables
  | Partial<{ endCursor: string; after: string }>;

export type Params<Data, Variables = void> = {
  query: DocumentNode;
  options: QueryHookOptions<Data, Variables> &
    Partial<{
      afterKey: string;
      // this option is used to control how pagination looks for data in the queryResult.data field. You should
      // usually set it to the name of your GraphQL query name.
      path: string;
      isAutomatic: boolean;
      context: Partial<Context>;
      scrollContainer: React.MutableRefObject<HTMLElement | null>;
      updateQuery: UpdateQuery<Data, Variables>;
      loaderContainer: React.MutableRefObject<HTMLElement | null>;
    }>;
};

type LoadMore<Data, Variables> = (
  variables: PaginationVariables<Variables>
) => Promise<ApolloQueryResult<Data>> | undefined;

type Return<Data, Variables> = QueryResult<
  Data,
  PaginationVariables<Variables>
> & {
  loadMore: LoadMore<Data, Variables>;
};

// Hash set to keep tracks of paginated queries with after cursor
// we use this set to keep track of already used cursors
// that way we don't make duplicate calls to queryResult.fetchMore()
const afterCursorSet = new Set();

const usePagination = <Data, Variables = void>({
  query,
  options: { isAutomatic, onCompleted, onError, path, updateQuery, ...options },
}: Params<Data, Variables>): Return<Data, Variables> => {
  afterCursorSet.clear();

  const [isIntersecting, setIntersecting] = useState(false);

  let timeoutId: number;

  const observer = new IntersectionObserver(([entry]) =>
    setIntersecting(entry.isIntersecting)
  );

  const queryResult = useQuery<Data, PaginationVariables<Variables>>(query, {
    onCompleted: (data: Data) => {
      onCompleted && onCompleted(data);
    },
    onError: (error: ApolloError) => {
      handleErrorMessages({ message: t('errors.500') });
      onError && onError(error);
    },
    ...options,
  });

  const loadMore = useCallback<LoadMore<Data, Variables>>(
    (variables) => {
      const vars = { ...options.variables, ...variables };

      const afterCursor = vars[options.afterKey || 'after'];

      // if we have seen this after cursor before
      // that means we already made a request for this one, and do nothing.
      if (afterCursorSet.has(afterCursor)) {
        return;
      }

      afterCursorSet.add(afterCursor);

      return (
        updateQuery &&
        queryResult.fetchMore({
          variables: vars,
          updateQuery: updateQuery,
        })
      );
    },
    [updateQuery, options.variables, queryResult]
  );

  const pageInfo = useMemo(
    () =>
      get(queryResult.data, `${path}.pageInfo`, {
        hasNextPage: false,
        endCursor: '',
      }) as PageInfo,
    [path, queryResult.data]
  );

  const shouldLoadMore = useMemo(() => {
    const isLoading = [NetworkStatus.fetchMore, NetworkStatus.loading].includes(
      queryResult.networkStatus
    );

    return pageInfo.hasNextPage && pageInfo.endCursor && !isLoading;
  }, [pageInfo, queryResult.networkStatus, options.loaderContainer]);

  useEffect(() => {
    const loaderContainer = options.loaderContainer?.current;
    if (loaderContainer) {
      observer.observe(loaderContainer);

      return (): void => {
        observer.unobserve(loaderContainer);
      };
    }
  }, [options.loaderContainer]);

  useEffect(() => {
    const handleScroll = (): Promise<ApolloQueryResult<Data>> | undefined =>
      loadMore({
        ...options.variables,
        [options.afterKey || 'after']: pageInfo.endCursor,
      });

    if (shouldLoadMore && (isIntersecting || isAutomatic)) {
      timeoutId = executeAfterTimeout(timeoutId, handleScroll, 300);
    }
  }, [
    shouldLoadMore,
    isIntersecting,
    loadMore,
    pageInfo.endCursor,
    options.afterKey,
    options.variables,
    isAutomatic,
  ]);

  useEffect(() => {
    if (!options.loaderContainer?.current && !isAutomatic) {
      const handleScroll = (): void => {
        if (shouldLoadMore) {
          loadMore({ ...options.variables, after: pageInfo.endCursor });
        }
      };

      const scrollListener = (): void => {
        timeoutId = executeAfterTimeout(timeoutId, handleScroll, 300);
      };

      const scrollContainer = options.scrollContainer?.current;

      if (scrollContainer) {
        scrollContainer.addEventListener('scroll', scrollListener);

        return (): void => {
          scrollContainer.removeEventListener('scroll', scrollListener);
        };
      }
    }
  }, [
    loadMore,
    options.scrollContainer,
    options.loaderContainer,
    options.variables,
    isAutomatic,
    pageInfo.endCursor,
    pageInfo.hasNextPage,
    queryResult.networkStatus,
    shouldLoadMore,
  ]);

  return { ...queryResult, loadMore };
};

export default usePagination;
