import { useState } from 'react';

import { User } from '@pipefyteam/types.base-types';
import { t } from '@pipefyteam/utils.i18n';
import { handleErrorMessages } from '@pipefyteam/utils.noty';

import { ME_QUERY } from 'Commons/graphql';
import {
  useQuery,
  QueryHookOptions,
  client,
} from 'utils/apolloAbstraction/apolloAbstraction';

export type UseMeResult = Readonly<{
  me?: User & { currentOrganizationRole?: string; timezone?: string };
  loading: boolean;
}>;

export type Options = QueryHookOptions<Pick<UseMeResult, 'me'>>;

const useMe = (options: Options = {}, skip = false): UseMeResult => {
  const { loading, data } = useQuery<{ me: UseMeResult['me'] }>(ME_QUERY, {
    client,
    skip,
    context: options.context || { internal: true },

    onError: (error) => {
      handleErrorMessages({
        message: t('errors.500'),
      });
    },
    ...options,
  });

  return {
    me: data?.me,
    loading,
  };
};

export default useMe;
